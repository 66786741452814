<template>
    <div class="addition-page">
        <section class="page-kv p-0">
            <div class="kv-img ratio-kv">
                <!-- 桌機圖 -->
                <img :src="recruitingImage.bannerPc" class="w-100 d-none d-md-inline-block" v-if="recruitingImage.bannerPc != ''">
                <!-- 手機圖 -->
                <img :src="recruitingImage.bannerMobile" class="w-100 d-inline-block d-md-none" v-if="recruitingImage.bannerMobile != ''">
            </div>
            <div class="kv-heading">
                <h1>人才招募</h1>
            </div>
        </section>
        <section class="about-block static-section-block">
            <div class="container" v-if="width > 767">
                <div class="static-content">
                    <div v-html="recruiting.recruiting"></div>
                </div>
            </div>
            <div class="container" v-else>
                <div class="static-content">
                    <div v-html="recruiting.recruitingMobile"></div>
                </div>
            </div>
        </section>
        <section class="about-block static-section-block bg-light">
            <div class="container">
                <div class="section-hgroup mt-40">
                    <h2 class="title-border text-black">誠摯邀請您一同加入我們！</h2>
                </div>
                <div class="inner-content">
                    <div class="accordion_recurit" id="accordionRecruit">
                        <div class="accordion-item" v-for="(item, index) in vacancyList">
                            <h2 class="accordion-header" :id="'heading' + index">
                                <button :class="'accordion-button' + (index == 0 ? '' : ' collapsed')" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index"
                                        :aria-expanded="index == 0 ? 'true' : 'false'" :aria-controls="'collapse' + index">
                                    <div class="title">{{ item.vacancyName }}</div>
                                    <div class="store">{{ item.vacancyNote }}</div>
                                    <i>
                                        <IconSortDown class="down"/>
                                        <IconSortUp class="up"/>
                                    </i>
                                </button>
                            </h2>
                            <div :id="'collapse' + index" :class="'accordion-collapse collapse' + (index == 0 ? ' show' : '')" :aria-labelledby="'heading' + index"
                                 data-bs-parent="#accordionRecruit">
                                <div :class="'accordion-body' + (index == 0 ? ' static-content' : '')">
                                    <div class="recruit-block">
                                        <h3>職位描述</h3>
                                        <p v-html="item.vacancySummary"></p>
                                    </div>
                                    <div class="recruit-block">
                                        <h3>工作細節</h3>
                                        <p v-html="item.vacancyDetail"></p>
                                    </div>
                                    <div class="recruit-block">
                                        <h3>細節說明</h3>
                                        <p v-html="item.vacancyDescription"></p>
                                    </div>
                                    <div class="btn-wrapper">
                                        <a :href="item.href" target="_blank" class="btn btn-recruit">立即應徵</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import IconSortUp from '@/components/icons/IconSortUp.vue'
import IconSortDown from '@/components/icons/IconSortDown.vue'

export default {
    name: 'Vacancy',
    data() {
        return {
            width: 0,
            recruitingImage: {
                bannerPc: '',
                bannerMobile: '',
            },
            recruiting: {},
            vacancyList: [],
            meta: {},
        }
    },
    components: {
        IconSortUp,
        IconSortDown
    },
    methods: {},
    mounted() {
        let house = this;
        this.width = $(window).width();
        this.axios.get('/api/recruiting')
            .then(response => (
                    this.recruitingImage = response.data.recruitingImage,
                        this.recruiting = response.data.recruiting,
                        this.vacancyList = response.data.vacancyList,
                        this.meta = response.data.meta,
                        document.title = this.meta.title,
                        $("meta[property='og:title']").attr("content", this.meta.title),
                        document.getElementsByTagName('meta')["description"].content = this.meta.description,
                        $("meta[property='og:description']").attr("content", this.meta.description)
                )
            )
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });
    }
}
</script>
